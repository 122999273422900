const styles = {
    spinner: {
        width: "50px",
        height: "50px",
        border: "5px solid rgba(0, 0, 0, 0.1)",
        borderTop: "5px solid #3498db",
        borderRadius: "50%",
        animation: "spin 1s linear infinite",
        margin: "20px"
    },
    spinnerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    keyframes: `
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    `,
};

export default styles;
