import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    menuList: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    }
})
export default useStyles;
