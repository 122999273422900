const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    },
    modal: {
        backgroundColor: "rgb(31, 31, 31)",
        color: "white",
        padding: "20px",
        borderRadius: "10px",
        maxWidth: "500px",
        width: "90%",
        textAlign: "center",
    },
    title: {
        fontFamily: "Montserrat-Light",
        fontWeight: 300,
        lineHeight: 1.3,
        fontSize: 25
    }
};

export default styles;
