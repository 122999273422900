import React from "react";
import ReactDOM from "react-dom";
import styles from "./styles";

const Modal = ({
    isOpen,
    title,
    children
}) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <p style={styles.title}>
                    {title}
                </p>
                {children}
            </div>
        </div>,
        document.body
    );
};

export default Modal;
