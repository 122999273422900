import React from 'react';
import useStyles from './style';
import { Text, useNCoreTheme } from 'ncore-web';
import {
    ayse,
    aysegul,
    baiysh,
    busra,
    esra,
    fatmaZehra,
    feyza,
    ibrahim,
    nisaPeri,
    omer,
    saziye,
    seyda
} from '../../../../../assets/image/teams/membersPhoto/index';
import TeamMember from './components';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TeamsSection = () => {
    const classes = useStyles();

    const {
        colors,
        spaces
    } = useNCoreTheme();

    const members = [
        {
            id: 0,
            fullName: "Esra Tosun",
            duty: "Yönetim Kurulu Başkanı",
            photo: esra
        },
        {
            id: 1,
            fullName: "Ayşe Melek Acar",
            duty: "Yönetim Kurulu Başkan Yardımcısı",
            photo: ayse
        },
        {
            id: 2,
            fullName: "Ömer Şahin",
            duty: "Yönetim Kurulu Başkan Yardımcısı",
            photo: omer
        },
        {
            id: 3,
            fullName: "Baiysh Sabirzhanov",
            duty: "Yönetim Kurulu Üyesi",
            photo: baiysh,
        },
        {
            id: 4,
            fullName: "Büşra Kaya",
            duty: "Yönetim Kurulu Üyesi",
            photo: busra,
        },
        {
            id: 5,
            fullName: "Şaziye Dağ",
            duty: "Yönetim Kurulu Üyesi",
            photo: saziye,
        },
        {
            id: 6,
            fullName: "Nisa Peri Aksoy",
            duty: "Yönetim Kurulu Üyesi",
            photo: nisaPeri,
        },
        {
            id: 7,
            fullName: "İbrahim Halil Altundağ",
            duty: "Yönetim Kurulu Üyesi",
            photo: ibrahim,
        },
        {
            id: 8,
            fullName: "Şeyda Açıkgöz",
            duty: "Yönetim Kurulu Üyesi",
            photo: seyda,
        },
        {
            id: 9,
            fullName: "Fatma Zehra Doğan",
            duty: "Yönetim Kurulu Üyesi",
            photo: fatmaZehra,
        },
        {
            id: 10,
            fullName: "Feyza Doğmuş",
            duty: "Yönetim Kurulu Üyesi",
            photo: feyza,
        },
        {
            id: 11,
            fullName: "Ayşegül Doğan",
            duty: "Yönetim Kurulu Üyesi",
            photo: aysegul,
        }
    ];

    let splitMembers = () => {
        let startIndex = 0;
        let splitedMembers = [];
        let i = 0;
        let membersPerPage = //sayfanın yanllızca açılış değerini alıyor büyüme küçülme durumlarında değişmiyor
            window.innerWidth <= 600
                ? 1
                : window.innerWidth <= 1000
                    ? 4
                    : 6;

        while (i < members.length) {
            let partMembers = [];

            for (i = startIndex; i < startIndex + membersPerPage && i < members.length; i++) {
                partMembers.push(members[i]);
            }

            splitedMembers.push(partMembers);
            startIndex += membersPerPage;
        }

        return splitedMembers;
    };

    const renderTeamsSection = () => {
        return <Carousel
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            emulateTouch={true}
            interval={3000}
            autoPlay={true}
            infiniteLoop={true}
        >
            {
                splitMembers().map((item) => {
                    return <div
                        className={classes.sliderItem}
                        style={{
                            gap: spaces.item
                        }}
                    >
                        {
                            item.map(subItem => {
                                return <TeamMember {...subItem} />
                            })
                        }
                    </div>
                })
            }
        </Carousel>
    }

    return <div
        className={classes.container}
        id='teams'
        style={{
            backgroundColor: colors.layer1
        }}
    >
        <div
            className={classes.titleContainer}
            style={{
                backgroundColor: colors.orange,
                padding: spaces.item
            }}
        >
            <Text
                variant='header13'
            >Ekibimiz</Text>
        </div>
        <div
            className={classes.sliderContainer}
            style={{
                gap: spaces.item,
                padding: spaces.container * 2
            }}
        >
            {renderTeamsSection()}
        </div>
    </div>
};

export default TeamsSection;
