const styles = {
    selectbox: {
        flexDirection: "column",
        position: "relative",
        display: "flex",
        width: "100%",
        '& select': {
            borderRadius: "10px",
            flexDirection: "row",
            cursor: "pointer",
            padding: "0 16px",
            display: "flex",
            outline: "none",
            border: "none",
            height: "50px",
        },
    },
};
export default styles;
