import { Button, Text, useNCoreTheme, TextInput, Dialog } from 'ncore-web';
import React, { useCallback, useEffect, useState } from 'react'
import useStyles from './style';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import contactBackground from '../../../assets/image/contact/contactBackground.png'
import axios from 'axios';
import Selectbox from "../../components/selectbox";
import Loading from "../../components/loading";
import Modal from "../../components/modal";
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../constants/index";

const Appeal = () => {

    const classes = useStyles();
    const navigate = useNavigate();

    const {
        colors
    } = useNCoreTheme();

    const [isVisibleDialog, setIsVisibleDialog] = useState({
        isVisible: false,
        title: null
    });

    const [selectCourse, setSelectCourse] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [tcNumber, setTcNumber] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthDate, setBirthDate] = useState("");

    const [courses, setCourses] = useState([]);
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);
    const [isCookieModalOpen, setIsCookieModalOpen] = useState(false);
    const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);

    const [modalTitle, setModalTitle] = useState("Oturum doğrulanıyor...");
    const [returnModalTitle, setReturnModalTitle] = useState("Bilinmeyen bir sorun oluştu.");

    const fetchCourseData = async () => {
        try {
            setModalTitle("Kurs listesi alınıyor...");
            setIsSessionModalOpen(true);
            setIsLoading(true);
            const response = await axios.get(`${API_URL}getAllCourse`);
            setCourses(response.data.courses);
        } catch (error) {
            setReturnModalTitle("Aktif herhangi bir eğitim bulunmamakta.");
            setIsReturnModalOpen(true);
        } finally {
            setIsSessionModalOpen(false);
            setIsLoading(false);
        }
    }

    const loginAppeal = useCallback(async () => {
        try {
            setModalTitle("Oturum doğrulanıyor...");
            setIsSessionModalOpen(true);
            setIsLoading(true);
            const response = await axios.get(`${API_URL}loginAppeal`);
            setToken(response.data.token);
        } catch (error) {
            console.log("kurslar:", courses);
            setReturnModalTitle("Oturum doğrulanamadı :(");
            setIsReturnModalOpen(true);
        } finally {
            setIsSessionModalOpen(false);
            setIsLoading(false);
        }
    }, [courses]);

    useEffect(() => {
        fetchCourseData();
    }, []);

    useEffect(() => {
        if (courses && courses.length > 0) {
            loginAppeal();
        }
    }, [courses, loginAppeal]);

    const handleSubmit = async () => {
        let variables = {
            courseID: selectCourse,
            firstName: firstName,
            lastName: lastName,
            tcNumber: tcNumber || undefined,
            email: email,
            phoneNumber: phoneNumber || undefined,
            birthDate: birthDate || undefined
        }
        try {
            const response = await axios.post(`${API_URL}postAppeal`, variables, {
                headers: {
                    Authorization: token
                }
            });

            setToken("");
            setReturnModalTitle(response.data.message);
            setIsReturnModalOpen(true);
        } catch (error) {
            let message = "";

            if (error.response.status === 401) {
                message += error.response?.data?.message;
                setToken("");
                setReturnModalTitle(message);
                setIsReturnModalOpen(true);
            }
            else {
                if (!selectCourse || selectCourse.length === 0) {
                    message += "Kurs seçimi yapmadın."
                }
                else {
                    message += error.response?.data?.message;
                }
                setIsVisibleDialog({
                    isVisible: true,
                    title: message
                });
            }
        }
    };

    const CookieConsent = () => {
        return (
            <div className={classes.cookieModal}>
                <Button
                    variant="outline"
                    title='Geri Dön'
                    onClick={() => navigate("/")}
                />
                <Button
                    title='Onaylıyorum'
                    onClick={() => setIsCookieModalOpen(false)}
                />
            </div>
        );
    };

    const ReturnModal = () => {
        return (
            <div className={classes.cookieModal}>
                <Button
                    variant="outline"
                    title='Geri Dön'
                    onClick={() => navigate("/")}
                />
            </div>
        )
    }

    return <div
        style={{
            display: "flex",
            flexDirection: "column"
        }}
    >
        <div
            className={classes.container}
            style={{
                backgroundImage: `url(${contactBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: '100%',
                height: '1000px',
            }}
        >
            <Navbar />
            <Modal isOpen={isSessionModalOpen} title={modalTitle}>
                <Loading isLoading={isLoading} />
            </Modal>
            <Modal isOpen={isCookieModalOpen} title={"Form işlemleri için zorunlu çerez onayı gerekmektedir"}>
                <CookieConsent />
            </Modal>
            <Modal isOpen={isReturnModalOpen} title={returnModalTitle}>
                <ReturnModal />
            </Modal>
            <div
                className={classes.mainContent}
            >
                <Text
                    color='body'
                    variant='header14'
                >
                    Eğitim Başvurusu
                </Text>
                <div
                    className={classes.inputArea}
                >
                    <Selectbox
                        data={courses.map(item => ({
                            title: item.title,
                            id: item.id
                        }))}
                        title={"Eğitim Seçin"}
                        handleChange={(e) => {
                            setSelectCourse(e.target.value);
                        }}
                    />
                    <TextInput
                        title='İsim'
                        placeholder='İsim'
                        isRequired
                        spreadBehaviour='free'
                        style={{
                            borderRadius: "32px",
                            alignSelf: "stretch",
                            backgroundColor: colors.inputAreaColor,
                        }}
                        onChangeText={(value) => {
                            setFirstName(value);
                        }}
                    />
                    <TextInput
                        title='Soyisim'
                        placeholder='Soyisim'
                        isRequired
                        spreadBehaviour='free'
                        style={{
                            borderRadius: "32px",
                            alignSelf: "stretch",
                            backgroundColor: colors.inputAreaColor,
                        }}
                        onChangeText={(value) => {
                            setLastName(value);
                        }}
                    />
                    <TextInput
                        title='Tc Numarası'
                        placeholder='Tc Numarası'
                        isRequired
                        spreadBehaviour='free'
                        style={{
                            borderRadius: "32px",
                            alignSelf: "stretch",
                            backgroundColor: colors.inputAreaColor,
                        }}
                        onChangeText={(value) => {
                            setTcNumber(value);
                        }}
                    />
                    <TextInput
                        title='E-mail'
                        placeholder='E-mail'
                        isRequired
                        spreadBehaviour='free'
                        style={{
                            borderRadius: "32px",
                            alignSelf: "stretch",
                            backgroundColor: colors.inputAreaColor,
                        }}
                        onChangeText={(value) => {
                            setEmail(value);
                        }}
                    />
                    <TextInput
                        title='Telefon Numarası'
                        placeholder='Telefon Numarası'
                        isRequired
                        spreadBehaviour='free'
                        style={{
                            borderRadius: "32px",
                            alignSelf: "stretch",
                            backgroundColor: colors.inputAreaColor,
                        }}
                        onChangeText={(value) => {
                            setPhoneNumber(value);
                        }}
                    />
                    <TextInput
                        title='Doğum Tarihi'
                        placeholder='Doğum Tarihi'
                        isRequired
                        spreadBehaviour='free'
                        style={{
                            borderRadius: "32px",
                            alignSelf: "stretch",
                            backgroundColor: colors.inputAreaColor,
                        }}
                        onChangeText={(value) => {
                            setBirthDate(value);
                        }}
                    />
                </div>
                <Button
                    textColor='body'
                    title="Gönder"
                    spreadBehaviour='free'
                    onClick={handleSubmit}
                />
            </div>

        </div>
        <Footer />
        <Dialog //Bu kısmı almıyor alıyor da mail gönderilince yani 3 dk sonra
            isVisible={isVisibleDialog.isVisible}
            variant='info'
            onOverlayPress={() => {
                setIsVisibleDialog({
                    isVisible: false,
                    title: null
                })
            }}
            title={isVisibleDialog.title ? isVisibleDialog.title : ""}
        >
        </Dialog>
    </div>
}

export default Appeal;
