import React from "react";
import styles from "./styles";

const Loading = ({
    isLoading
}) => {
    return (
        <>
            {isLoading &&
                <div style={styles.spinnerWrapper}>
                    <div style={styles.spinner}></div>
                    <style>{styles.keyframes}</style>
                </div>
            }
        </>
    );
};

export default Loading;
