import React from 'react';
import injectSheet from 'react-jss';
import styles from './styles';

const Selectbox = ({
    selectedValue,
    handleChange,
    classes,
    title,
    data,
}) => {
    return <div
        className={classes.selectbox}
    >
        <select
            value={selectedValue}
            onChange={handleChange}
            style={{
                backgroundColor: "rgb(31, 31, 31)",
                color: "rgb(160, 160, 160)",
                fontSize: "14px"
            }}
        >
            <option>{title}</option>
            {data.map((item) => (
                <option
                    key={item.id}
                    value={item.id}
                >
                    {item.title}
                </option>
            ))}
        </select>
    </div>;
};
export default injectSheet(styles)(Selectbox);
